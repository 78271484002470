import React from 'react';

export default () => (
  <section className='container-fluid'>
        <div className='row m-10-hor'>
        <div className='col-12 text-center'>
          <div className='subheading'>How We Work</div>
          <div className='heading'>Our Workflow</div>
          <div className='row'>
            <div className='col-md-8 mx-auto'>
              <p className='content'>
              We use the best software development practices in everything we do. <span className='br'></span>HERE’S A CLOSER LOOK AT OUR PROCESS:<span className='br'></span>
              </p>
            </div>
          </div>
        </div>
        

        <div className='col-md-4'>
          <div className='pricelist mt-0'>
            <div className="heading">Phase A - Solution Proposal</div>
          {/*  <div className="bigprice"><span className='text-gradient'>$49</span> <small>Month</small></div> */}
          <ul className="list">
              <li> 1. Initial Meeting</li>
              <li> 2. Planning Meeting</li>
              <li> 3. Propose Solution</li>
              <li> 4. Meet/email Questions</li>
              <li> 5. Final Solution Proposal</li>
            </ul>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='pricelist mt-0'>
            <div className="heading">Phase B - Solution Development</div>
          {/*  <div className="bigprice"><span className='text-gradient'>$49</span> <small>Month</small></div> */}
          <ul className="list">
              <li> 1. Development of Prototype Starts</li>
              <li> 2. Discussions after prototype demo</li>
              <li> 3. Develop final solution</li>
              <li> 4. Testing & Sign-off</li>
            </ul>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='pricelist mt-0'>
            <div className="heading">Phase C - Support & Maintenance</div>
          {/*  <div className="bigprice"><span className='text-gradient'>$49</span> <small>Month</small></div> */}
            <ul className="list">
              <li> 1. Monthly Subscription</li>
              <li> 2. Consultation Based</li>
            </ul>
          </div>
        </div>

        </div>
    </section>
  
);
