import React from 'react';
import posed from 'react-pose';
import Slider from '../components/sliderhome';
//import Carouselteam from '../components/carouselteam';
import Bannercontact from '../components/bannercontact';
import Pricing from '../components/pricing';
//import Testimony from '../components/carouseltestimony';
import Footer from '../components/footer';

const ListContainer = posed.div({
  enter: { staggerChildren: 20 },
  exit: { staggerChildren: 20, staggerDirection: 0 }
});

const Item = posed.section({
  enter: { y: 0, opacity: 1 },
  exit: { y: 5, opacity: 0 }
});


export default () => (
  <ListContainer>
      <Item className="jumbotron jumbomain">
          <Slider />
          <div className="icon-scroll-wraper">
            <div className="icon-scroll">
              <div className="icon-scroll-screen"></div>
            </div>
          </div>
      </Item>

      <section className='container-fluid black'>
        <div className='row m-10-hor'>

          <div className='col-md-5'>
            <div className='subheading'>
              We are a
            </div>
            <div className='heading'>
              Software Development <span className='br'></span> Company
            </div>
          </div>

          <div className='col-md-7'>
            <div className='content'>
            Vesbeck was established to focus on making your business processes easier and saving you time on admin so you can focus on doing what you love. We either improve your current system, add-on additional solutions/support functions or create totally new customized solutions that best suits your need.
            </div>
            <div className='content'>
            We pride ourself by providing the best software, website and financial solutions to the clients by tailoring as per their business requirements. </div>
          </div>

        </div>
    </section>

    <section className='container-fluid pt-0 black'>
        <div className='row m-10-hor'>
         <div className='col-md-4'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              01.
            </div>
            <div className='heading'>
            Software Development
            </div>
            <div className='content'>
            We develop custom software and to specification for our clients.
            it is our goal to provide our clients with cost effective, professional and efficient software.
            </div>
          </div>
         </div>

         <div className='col-md-4'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              02.
            </div>
            <div className='heading'>
            Mobile App Development
            </div>
            <div className='content'>
            Our mobile app solutions are created to suit the specific objectives of our clients, 
            including seamless integration with existing information systems and brands.
            </div>
          </div>
         </div>

         <div className='col-md-4'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              03.
            </div>
            <div className='heading'>
            Website Design & Hosting
            </div>
            <div className='content'>
            We design website from the scratch and also provide website 
            hosting as well as domain registration to make your website 
            accessible in the World Wide Web. 
            </div>
          </div>
         </div>
         
         <div className='col-md-4'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              04.
            </div>
            <div className='heading'>
            Financial Services
            </div>
            <div className='content'>
            We offer financial services through financial institutions, 
            financial markets and financial instruments to serve the needs of 
            individuals, institutions, and corporate.
            </div>
          </div>
         </div>

         <div className='col-md-4'>
          <div className='col-feature'>
            <div className='sub-color text-gradient'>
              05.
            </div>
            <div className='heading'>
            CAT & IT Tutoring
            </div>
            <div className='content'>
            We provide face to face and online CAT and IT tuition  across the 
            country. Our tutors are handpicked from the best universities and thoroughly interviewed.
            </div>
          </div>
         </div>

        </div>
    </section>

    <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className='col-12 text-center'>
            <div className='subheading'>Our team</div>
            <div className='heading'>About Our Team</div>
            <Bannercontact/>

          </div>
         </div>
    </section>
    {/* 
    <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className='col-12 text-center'>
            <div className='subheading'>Basic info our team</div>
            <div className='heading'>About Our Team</div>
            <div className='row'>
              <div className='col-md-8 mx-auto'>
                <p className='content'>
                  Curabitur mollis bibendum luctus. 
                  Duis suscipit vitae dui sed suscipit. Vestibulum auctor 
                  nunc vitae diam eleifend, in maximus metus sollicitudin. 
                  Quisque vitae sodales lectus. Nam p
                  orttitor justo sed mi finibus, 
                  vel tristique risus faucibus. 
                </p>
              </div>
            </div>
          </div>
          <Carouselteam />
        </div>
    </section> */}
   
    <Pricing />
   {/*  <Testimony /> */}
    <Footer />

  </ListContainer>
);
