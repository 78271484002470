import React from 'react';
import { Link } from '@reach/router';

export default () => (
  <section className='container-fluid'>
        <div className='row m-10-hor'>
          

          <div className='col-md-6'>
            <img src='./img/mocks.jpg' alt='imgbanner' className='w-100 border-radius'/>
            
          </div>
          <div className='col-md-6 centered'>
            <div>
              <div className="subheading">
              We are passionate 
              </div>
              <div className="heading">
                About Software Development
              </div>
              <p>
              The Business is spearheaded by the three founding members,  Salome , Eugéne and Vian . 
              Salome has more than 20 years’ experience in Software Development for major banks, logistics and steel companies. 
              She holds a BSc Computer Science degree.               
              <span className='br'></span>Eugéne has over 20 years’ experience in management cost consulting 
              and forecasting on big projects' start-ups such as the Gautrain, etc. 
              </p>
              <Link className='btn' to="/contact">
                <span>Contact Us</span>
              </Link>
            </div>
          </div>
          
        </div>
    </section>
);
